<template>
  <div>
    <div class="reply-engine-widget" v-show="setupComplete"
      :style="{ 'background-color': configuration.background_color }">
      <div class="row">
        <div class="col">
          <div v-if="step === 2" class="text-center font-size-20" :style="{ 'color': configuration.main_text_color }">
            <strong>Would you like to have access to our software?</strong>
          </div>
          <h3 v-else-if="step === 3" class="h1 text-center" :style="{ 'color': configuration.main_text_color }">Thank You
          </h3>
          <h3 v-else class="h1 text-center" :style="{ 'color': configuration.main_text_color }">
            {{ configuration.header_text }}</h3>
        </div>
      </div>
      <div v-if="returnType === 'posts'">
        <div v-if="step === 1" class="row place-search-row">
          <div :style="{ 'flex-direction': 'row !important', 'justify-content': 'space-between' }"
            :class="{ 'col double-column': step === 1, 'col-12': step > 1 }">
            <div :style="{ 'width': '50%' }">
                <input  type="text" ref="placesearch" id="placesearch" :placeholder="placeHolder"
                    :class="{ 'has-error': errors.business }" />
                <span v-if="errors.business" class="error" :style="{ color: configuration.error_text_color }">{{
              errors.business }}</span>
            </div>
            <select v-model="selectedOption" :class="{ 'has-error': errors.business }"
              style="width: 30%; border-radius: 7px; padding: 12px;">
              <option value="Famous Quotes">Famous Quotes</option>
              <option value="Educational Content">Educational Content</option>
              <option value="Seasonal/Holiday Posts">Seasonal-Holiday Posts</option>
              <option value="Engagement Posts">Engagement Posts</option>
              <option value="Fun Facts">Fun Facts</option>
              <option value="Humorous Posts">Humorous Posts</option>
              <option value="Quote of the Day">Quote of the Day</option>
              <option value="Interactive Content">Interactive Content</option>
            </select>
            <div>
              <div v-if="isBusy || forceBusy" v-html="spinner"></div>
              <button v-else class="btn" @click="createProspect"
                :style="{ 'padding-left': '30px',  'padding-right': '30px', 'color': configuration.primary_button_text_color, 'background-color': configuration.primary_button_background_color }">
                {{ returnType === 'posts' ? 'Generate Post' : buttonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="step === 1" class="row place-search-row">
          <div :class="{ 'col double-column': step === 1, 'col-12': step > 1 }">
            <input type="text" ref="placesearch" id="placesearch" :placeholder="placeHolder"
              :class="{ 'has-error': errors.business }" />
            <span v-if="errors.business" class="error" :style="{ color: configuration.error_text_color }">{{
        errors.business }}</span>
          </div>
          <div v-if="step === 1" class="col text-center" :class="{ 'center-content': !errors.business }">
            <div v-if="isBusy || forceBusy" v-html="spinner"></div>
            <button v-else class="btn" @click="createProspect"
              :style="{ 'color': configuration.primary_button_text_color, 'background-color': configuration.primary_button_background_color }">
              {{ returnType === 'posts' ? 'Generate Post' : buttonText }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="step === 2" class="row">
        <div class="col">
          <div class="row">
            <div class="col-lg-3 mt-2">
              <input v-model="form.first_name" type="text" :class="{ 'has-error': errors.first_name }"
                placeholder="First Name..." />
              <span v-if="errors.first_name" class="error" :style="{ color: configuration.error_text_color }">{{
        errors.first_name }}</span>
            </div>
            <div class="col-lg-3 mt-2">
              <input v-model="form.email" type="text" :class="{ 'has-error': errors.email }" placeholder="Email..." />
              <span v-if="errors.email" class="error" :style="{ color: configuration.error_text_color }">{{ errors.email
                }}</span>
            </div>
            <div class="col-lg-3 mt-2">
              <!--              <input v-model="form.phone_number" v-mask="'(###) ###-####'" type="tel" :class="{ 'has-error': errors.phone_number }" placeholder="Your Phone Number Here..." />-->
              <vue-tel-input v-model="form.phone_number"
                :input-options="{ showDialCode: false, tabindex: false, placeholder: 'Phone...' }"
                @input="onPhoneInput"><template slot="arrow-icon"> <span
                    class="vti__dropdown-arrow">&nbsp;▼</span></template></vue-tel-input>
              <span v-if="errors.phone_number" class="error" :style="{ color: configuration.error_text_color }">{{
        errors.phone_number
      }}</span>
            </div>
            <div class="col-lg-3 mt-2 text-center">
              <div v-if="isBusy" v-html="spinner"></div>
              <button v-else class="btn" @click="createLead"
                :style="{ 'color': configuration.primary_button_text_color, 'background-color': configuration.primary_button_background_color }">I
                want this</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 3" class="row mt-3">
        <div class="col">
          <h1 class="text-center" style="font-weight: normal; font-style: italic"
            :style="{ 'color': configuration.main_text_color }">Please check your email for more details.</h1>
        </div>
      </div>
    </div>
    <div v-if="step === 2 && (isReplyEngine || returnType === 'replies')" class="rwg-app-reply-sample mb-5">
      <div v-for="(review) in placeResultData.reviews" :key="review.id">
        <ReviewReplied :review="review">
          <div class="reply-row" style="margin-left:70px">
            <!--          :typing-speed="i === 0 ? 75 : 50"-->
            <TypeWriter v-if="review.review_response" :text="review.review_response" />
            <div v-else class="spin">
              <div v-html="spinner" class="d-inline-block"></div>
              <span class="ml-2">Waiting for AI to write a reply</span>
            </div>
          </div>
        </ReviewReplied>
      </div>
    </div>
    <div v-if="step === 2 && returnType === 'image'" class="rwg-app-reply-sample mb-5">
      <div style="text-align: center">
        <img :src="socialImageExample" alt="Social Review Example" style="max-width: 100%; height: auto"
          @load="imageLoaded" />
      </div>
      <div v-if="!imageCreateFinished" style="text-align: center">
        <div v-html="spinner"></div> Creating Your Image
      </div>
      <div v-if="imageCreateFinished" style="text-align: center">
        <a :href="`${downloadRemoveUrl}?image_url=${encodedSocialImageExample}`" target="_blank"
          class="btn btn-link pr-0">Download Sample</a>
      </div>
    </div>
    <div v-if="step === 2 && returnType === 'posts'" class="rwg-app-reply-sample mb-5">
      <div class="modal_content">
        <div class="post_content">
          <div class="post">
            <div v-if="loading" class="loading_div">
              <h2 class="generate">Generating Post!</h2>
              <b-spinner class="spin" large></b-spinner>
            </div>
            <div v-else>
              <div class="post_text_wrapper">
                <p id="post_text" class="post_text" v-html="newPost.text"></p>
                <br>
                <p id="post_tags" class="post_tags" v-html="newPost.hashtags"></p>
              </div>
              <div class="post_image_wrapper">
                <img id="post_image" :src="newPost.imageUrl" alt="" class="post_image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Vue from 'vue';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ReviewReplied from "../../../src/components/Common/ReviewReplied.vue";
import { countryToAlpha2 } from "country-to-iso";
import axios from "axios";
axios.defaults.baseURL = (process.env.NODE_ENV === 'production' ? window.location.origin.includes('staging.') ? 'https://api.replyengine.io/' : 'https://api.replyengine.io/' : 'https://api.replyengine.io/') + 'api/v1';
import VueScript2 from "vue-script2";
import TypeWriter from '../../../src/components/Common/TypeWriter.vue';
import { VueMaskDirective } from 'v-mask'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.directive('mask', VueMaskDirective);
Vue.use(VueTelInput);
const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name'
};
export default {
  components: {
    VueGoogleAutocomplete,
    TypeWriter,
    ReviewReplied,
  },
  props: {
    startingStep: {
      type: Number,
      default: 1
    },
    // eslint-disable-next-line vue/prop-name-casing
    agencyId: {
      type: [Number, String],
      required: true
    },
    headerText: {
      type: String,
      default: 'Search For Your Business'
    },
    // eslint-disable-next-line vue/prop-name-casing
    googleApiKey: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '#0e8dc7'
    },
    mainTextColor: {
      type: String,
      default: '#ffffff'
    },
    primaryButtonBackgroundColor: {
      type: String,
      default: '#34A853'
    },
    primaryButtonTextColor: {
      type: String,
      default: '#ffffff'
    },
    secondaryButtonBackgroundColor: {
      type: String,
      default: '#FFBE0B'
    },
    secondaryButtonTextColor: {
      type: String,
      default: '#ffffff'
    },
    copyAreaBackgroundColor: {
      type: String,
      default: '#EFF3F7'
    },
    copyAreaTextColor: {
      type: String,
      default: '#596482'
    },
    copyButtonBackgroundColor: {
      type: String,
      default: '#537BBA'
    },
    copyButtonTextColor: {
      type: String,
      default: '#ffffff'
    },
    spinnerColor: {
      type: String,
      default: '#000000'
    },
    errorTextColor: {
      type: String,
      default: '#4e0202'
    },
    defaultMapQuery: {
      type: String,
      default: ''
    },
    qrText: {
      type: String,
      default: ''
    },
    placeHolder: {
      type: String,
      default: 'Start Typing To Find Your Business'
    },
    postPlaceHolder: {
      type: String,
      default: 'Famous Quote'
    },
    manuallyCreated: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: "Submit"
    },
    forceBusy: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: ''
    },
    isTapEngine: {
      type: Boolean,
      default: false
    },
    isReplyEngine: {
      type: Boolean,
      default: false
    },
    isSocialReview: {
      type: Boolean,
      default: false
    },
    isSocialFusion: {
      type: Boolean,
      default: false
    },
    isReviewSurge: {
      type: Boolean,
      default: false
    },
    returnType: {
      type: String,
      default: "widget"
    }
  },
  components: {
    vSelect
  },
  data() {
    return {
      showSocialPostModal: false,
      loading: false,
      showModal: false,
      newPost: {
        text: '',
        imageUrl: '',
        hashtags: '',
        id: ''
      },
      options: ['Famous Quotes', 'Educational Content', 'Seasonal-Holiday Posts', 'Engagement Posts', 'Fun Facts', 'Humorous Posts', 'Quote of the Day', 'Interactive Content'],
      selectedOption: 'Famous Quotes',  // this will store the selected option
      postPlaceHolder: "Select a content type", // example placeholder
      errors: {
        business: false
      },
      configuration: {
        error_text_color: '#FF0000' // example error text color
      },
      phone_valid: false,
      step: this.startingStep,
      street: '',
      place: null,
      addressData: null,
      google_api_key: this.googleApiKey,
      reduced_address: null,
      placeResultData: {
        place_id: '',
        url: '',
        reviews: []
      },
      autocompleteText: '',
      placeFields: ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity', 'website', 'formatted_phone_number', 'reviews', 'rating', 'user_ratings_total'],
      scriptLoaded: false,
      mapReady: false,
      isBusy: false,
      setupComplete: false,
      showCopied: false,
      imageCreateFinished: false,
      mapQuery: this.defaultMapQuery,
      errors: {},
      unique_id: '',
      user_id: this.userId,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        business_name: '',
        business_phone: '',
        business_website: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        lat: '',
        lng: '',
        place_id: '',
        google_maps_url: '',
        agency_id: '',
        types: [],
        origin: '',
        sample_reviews: [],
        manually_created: false,
        is_tap_engine: this.isTapEngine,
        is_reply_engine: this.isReplyEngine,
        is_social_review: this.isSocialReview,
        is_social_fusion: this.isSocialFusion,
        is_text_requester: this.isReviewSurge,
        return_type: this.returnType,
      },
      configuration: {
        background_color: this.backgroundColor,
        main_text_color: this.mainTextColor,
        primary_button_background_color: this.primaryButtonBackgroundColor,
        primary_button_text_color: this.primaryButtonTextColor,
        copy_area_background_color: this.copyAreaBackgroundColor,
        copy_area_text_color: this.copyAreaTextColor,
        copy_button_background_color: this.copyButtonBackgroundColor,
        copy_button_text_color: this.copyButtonTextColor,
        error_text_color: this.mainTextColor,
        header_text: this.headerText,
        qr_text: this.qrText
      },
      oneReview: null
    }
  },
  watch: {
    step(newValue, oldValue) {
      if (newValue === 2 && this.returnType === 'posts') {
        this.generatePost();
      }
    },
    'form.first_name': function (newVal, oldVal) {
      if (newVal && this.errors.hasOwnProperty('first_name')) {
        Vue.delete(this.errors, 'first_name');
      }
    },
    'form.last_name': function (newVal, oldVal) {
      if (newVal && this.errors.hasOwnProperty('last_name')) {
        Vue.delete(this.errors, 'last_name');
      }
    },
    'form.email': function (newVal, oldVal) {
      if (newVal && this.errors.hasOwnProperty('email')) {
        Vue.delete(this.errors, 'email');
      }
    },
    'form.phone_number': function (newVal, oldVal) {
      if (newVal && this.errors.hasOwnProperty('phone_number')) {
        Vue.delete(this.errors, 'phone_number');
      }
    },
    startingStep: function (newVal) {
      this.step = newVal;
    },
    backgroundColor: function (newVal) {
      this.configuration.background_color = newVal;
    },
    mainTextColor: function (newVal) {
      this.configuration.main_text_color = newVal;
    },
    primaryButtonBackgroundColor: function (newVal) {
      this.configuration.primary_button_background_color = newVal;
    },
    primaryButtonTextColor: function (newVal) {
      this.configuration.primary_button_text_color = newVal;
    },
    copyAreaBackgroundColor: function (newVal) {
      this.configuration.copy_area_background_color = newVal;
    },
    copyAreaTextColor: function (newVal) {
      this.configuration.copy_area_text_color = newVal;
    },
    copyButtonBackgroundColor: function (newVal) {
      this.configuration.copy_button_background_color = newVal;
    },
    copyButtonTextColor: function (newVal) {
      this.configuration.copy_button_text_color = newVal;
    },
    errorTextColor: function (newVal) {
      this.configuration.error_text_color = newVal;
    },
    headerText: function (newVal) {
      this.configuration.header_text = newVal;
    },
    qrText: function (newVal) {
      this.configuration.qr_text = newVal;
    }
  },
  computed: {
    addressString() {
      let address_string = '';
      if (this.placeResultData.hasOwnProperty('street_number') && this.placeResultData.hasOwnProperty('route')) {
        address_string = this.placeResultData.street_number + ' ' + this.placeResultData.route;
        if (this.placeResultData.hasOwnProperty('subpremise')) {
          address_string += ' ' + this.placeResultData.subpremise;
        }
      } else if (this.placeResultData.hasOwnProperty('formatted_address')) {
        address_string = this.placeResultData.formatted_address.split(',')[0]
      }
      return address_string;
    },
    socialImageExample() {
      if (this.placeResultData.reviews.length) {
        const sortedReviews = this.placeResultData.reviews.sort((a, b) => {
          // Sort by rating in descending order
          if (a.rating !== b.rating) {
            return b.rating - a.rating;
          }
          // If ratings are equal, sort by timestamp in descending order
          return b.time - a.time;
        });
        const review = sortedReviews[0];
        this.oneReview = review;
        const review_text = review.text;
        const review_rating = review.rating;
        const author_title = review.author_name;
        const business_name = this.placeResultData.name;
        const phone_number = this.placeResultData.formatted_phone_number;
        const author_image = review.profile_photo_url;
        return `https://api.replyengine.io/api/v1/sample-social-image?review_rating=${encodeURI(review_rating)}&text=${encodeURI(review_text)}&reviewer=${encodeURI(author_title)}&business_name=${encodeURI(business_name)}&business_phone=${encodeURI(phone_number)}&icon=${author_image}`;
      }
    },
    encodedSocialImageExample() {
      return btoa(this.socialImageExample);
    },
    downloadRemoveUrl() {
      return (process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? "https://api.showmyrep.io/" : process.env.VUE_APP_BASE_API_LOCAL) + 'api/v1/remote-download-image';
    },
    customLink() {
      return (process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? window.location.origin : 'https://replyengine.io') + '/r/' + this.unique_id;
    },
    spinner() {
      return `<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="${this.spinnerColor}">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"/>
            </path>
          </g>
        </g>
      </svg>`;
    }
  },

  mounted() {
    if (!this.googleApiKey) {
      // We are being loaded from outside of site and need to get the API Key
      axios.post('/agency-data', { agency_id: this.agencyId })
        .then((res) => {
          let embed_settings = res.data.data.embed_form_settings;
          if (this.returnType === 'image') {
            embed_settings = res.data.data.social_review_embed_form_settings;
          }
          if (this.returnType === 'posts') {
            embed_settings = res.data.data.social_fusion_embed_form_settings;
          }

          this.configuration.header_text = embed_settings.title;
          this.configuration.background_color = embed_settings.background_color;
          this.configuration.main_text_color = embed_settings.main_text_color;
          this.configuration.primary_button_background_color = embed_settings.primary_button_background_color;
          this.configuration.primary_button_text_color = embed_settings.primary_button_text_color;
          this.configuration.secondary_button_background_color = embed_settings.secondary_button_background_color;
          this.configuration.secondary_button_text_color = embed_settings.secondary_button_text_color;
          this.configuration.copy_area_background_color = embed_settings.copy_area_background_color;
          this.configuration.copy_area_text_color = embed_settings.copy_area_text_color;
          this.configuration.copy_button_background_color = embed_settings.copy_button_background_color;
          this.configuration.copy_button_text_color = embed_settings.copy_button_text_color;
          this.configuration.qr_text = embed_settings.qr_text;
          this.google_api_key = res.data.data.google_api_key;
          VueScript2.load(`https://maps.googleapis.com/maps/api/js?key=${this.google_api_key}&libraries=places`).then(() => {
            this.initMaps();
            this.setup();
          })
        })
        .catch((error) => {
          //eslint-disable-next-line no-console
          console.log(error)
        })
    } else {
      this.google_api_key = this.googleApiKey;
      VueScript2.load(`https://maps.googleapis.com/maps/api/js?key=${this.google_api_key}&libraries=places`).then(() => {
        this.initMaps();
        this.setup();
      })
    }
    this.form.agency_id = this.agencyId;
  },

  methods: {
    generatePrompt() {
      const today = new Date();
      const dateStr = today.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

      const categoryPrompts = {
        'Famous Quotes': 'Generate a unique famous quote relevant to business or general life. Ensure the quote is distinct from previous responses by categorizing it under themes like "leadership," "innovation," "perseverance," "teamwork," or "success." Only one quote, not unknown THE POPULAR QUOTE. Please don’t add any other text just a quote. The quote should not be of UNknown author, author should be well known.',
        'Educational Content': 'Create a piece of educational content that provides useful and unique information. Ensure the response covers a different topic or aspect, such as "history," "science," "technology," "health," or "personal development."',
        'Seasonal-Holiday Posts': `Generate a unique greeting or message for a holiday or special event. Check what event is today and according to that create post. Today's date is ${dateStr}`,
        'Engagement Posts': 'Create a unique question or prompt to engage the audience. Ensure the question is distinct by focusing on various themes like "daily habits," "personal goals," "favorite hobbies," "memorable experiences," or "current events."',
        'Fun Facts': 'Generate an interesting and unique fun fact. Ensure the fact is different by categorizing it under themes like "animals," "history," "space," "human body," or "technology."',
        'Humorous Posts': 'Create a funny and unique meme or joke. Ensure the response is distinct by focusing on different themes such as "workplace humor," "daily life," "technology jokes," "relationship humor," or "puns."',
        'Quote of the Day': 'Generate a unique motivational or thought-provoking quote for the day. Ensure the quote is distinct and categorized under themes like "inspiration," "wisdom," "courage," "creativity," or "resilience."',
        'Interactive Content': 'Create a unique piece of interactive content like a poll or challenge. Ensure the response offers a different activity by focusing on various themes like "preferences," "trivia," "challenges," "surveys," or "quizzes."'
      };

      let prompt = categoryPrompts[this.selectedOption];

      return prompt;
    },
    generatePost() {
      const prompt = this.generatePrompt();
      this.loading = true;

      axios.post('/generate-widget-post', {
          prompt: prompt,
          user_id: this.user_id,
          agency_id: this.agencyId,
        }).then((res) => {
          this.newPost = {
            text: res.data.data.text,
            hashtags: res.data.data.hashtags,
            imageUrl: res.data.data.imageUrl,
            date: new Date().toLocaleString(),
          };
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    imageLoaded() {
      console.log(this.imageCreateFinished);
      this.imageCreateFinished = true;
      console.log(this.imageCreateFinished);
    },
    onPhoneInput(input, phoneObject) {
      this.phone_valid = typeof phoneObject == 'object' && phoneObject.valid;
    },
    setup() {

      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.placesearch,
        {
          types: ['establishment']
        }
      );
      //this.autocomplete.setFields(this.placeFields);
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
      this.setupComplete = true;
      if (this.searchText !== '') {
        this.$refs.placesearch.value = this.searchText;
      }
    },
    downloadImage(imageUrl) {
      var link = document.createElement('a');
      link.href = imageUrl;
      link.target = '_blank';
      link.download = 'socialreview_sample.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * When a place changed
     */
    onPlaceChanged() {
      Vue.delete(this.errors, 'business');
      let place = this.autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place);
        return;
      }
      if (place.address_components !== undefined) {
        // return returnData object and PlaceResult object
        this.selected(this.formatResult(place), place);
        // update autocompleteText then emit change event
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.customLink);
      this.showCopied = true;
      setTimeout(() => this.showCopied = false, 2000)
    },
    resetErrors() {
      this.errors = {};
    },
    createProspect() {
      this.isBusy = true;
      // this.resetErrors();
      if (this.addressData === null) {
        this.errors.business = "Please select your business first";
      }
      if (this.addressData !== null && (!this.placeResultData.hasOwnProperty('place_id') || (this.placeResultData.hasOwnProperty('place_id') && this.placeResultData.place_id === ""))) {
        this.errors.business = "Sorry, but we could not find a place ID identifier for your business";
      }
      if (Object.keys(this.errors).length) {
        this.isBusy = false;
        return
      }
      this.reduced_address = this.placeResultData.address_components.reduce((seed, { long_name, types }) => {
        types.forEach(t => {
          seed[t] = long_name;
        });
        return seed;
      }, {});
      const country = countryToAlpha2(this.addressData.country);
      let city = '';
      let address = '';
      const state = this.addressData.administrative_area_level_1;
      const zip = this.addressData.postal_code;
      if (country === 'US') {
        city = this.reduced_address.hasOwnProperty('neighborhood') ? this.reduced_address.neighborhood : this.addressData.locality;
        address = this.addressString;
      } else {
        city = this.reduced_address.hasOwnProperty('postal_town') ? this.reduced_address.postal_town : this.reduced_address.hasOwnProperty('locality') ? this.addressData.locality : null
        /* if(this.reduced_address.hasOwnProperty('premise')){
          address += this.reduced_address.premise + ' ';
        } */
        if (this.reduced_address.hasOwnProperty('street_number')) {
          address += this.reduced_address.street_number + ' ' + this.reduced_address.route;
        } else if (this.reduced_address.hasOwnProperty('subpremise') && this.reduced_address.hasOwnProperty('premise') && this.reduced_address.hasOwnProperty('route')) {
          address += this.reduced_address.subpremise + ', ' + this.reduced_address.premise + ', ' + this.reduced_address.route;
        } else {
          const address_pieces = this.placeResultData.formatted_address.split(', ');
          let new_address_array = [];
          address_pieces.every(piece => {
            if (piece.startsWith(city) || piece.startsWith(state) || piece.startsWith(this.addressData.country) || piece.startsWith(zip)) {
              return false;
            } else {
              new_address_array.push(piece);
              return true;
            }
          });
          address += new_address_array.join(', ');
        }
      }
      this.form.business_name = this.placeResultData.name;
      this.form.business_phone = this.placeResultData.formatted_phone_number;
      this.form.business_website = this.placeResultData.website;
      this.form.address = address;
      this.form.city = city;
      this.form.state = state;
      this.form.zip = zip;
      this.form.country = country;
      this.form.lat = this.addressData.latitude;
      this.form.lng = this.addressData.longitude;
      this.form.place_id = this.placeResultData.place_id;
      this.form.google_maps_url = this.placeResultData.url;
      this.form.types = this.placeResultData.types;
      this.form.origin = window.location.href;
      this.form.manually_created = this.manuallyCreated;
      this.form.is_reply_engine = this.isReplyEngine;
      this.form.is_tap_engine = this.isTapEngine;
      this.form.is_social_fusion = this.isSocialFusion;
      this.form.is_text_requester = this.isReviewSurge;
      const win = window;
      axios.post('/create-lead', this.form)
        .then((res) => {
          this.unique_id = res.data.data.unique_id;
          if (this.manuallyCreated) {
            this.$emit('prospectCreated', res.data.data);
          } else {
            this.step++;
            this.placeResultData.reviews.forEach(review => {
              axios.post('/sample-replies', {
                review: review.text,
                reviewers_name: review.author_name,
                review_stars: review.rating + " out of 5 stars. "
              }).then((res) => {
                console.log(res);
                if (
                  res.data.hasOwnProperty('reply')
                ) {
                  const reply = res.data.reply;
                  review.review_response = reply.replace('/\n/', '*');
                } else {
                  console.error(res.data.error);
                }

              });
            });
          }
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          //eslint-disable-next-line no-console
          console.log(error)
        });
    },
    checkErrors() {
      if (this.form.first_name === '') {
        this.errors.first_name = "Please enter First Name";
      }
      if (!/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.form.email)) {
        this.errors.email = "Please enter a valid email"
      }
      if (!this.phone_valid) {
        this.errors.phone_number = "Please enter a valid phone number"
      }
    },
    createLead() {
      this.isBusy = true;
      this.resetErrors();
      this.checkErrors();
      if (this.form.business_name === '') {
        // Somehow they are here and a prospect was not created
        this.createProspect();
      }
      if (Object.keys(this.errors).length) {
        this.isBusy = false;
        return
      }
      axios.post('/update-lead', this.form)
        .then(() => {
          this.step++;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          //eslint-disable-next-line no-console
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.hasOwnProperty('errors') && error.response.data.errors.hasOwnProperty('email')) {
              this.errors.email = error.response.data.errors.email[0];
            }
          }
        })
    },
    initMaps() {
      this.scriptLoaded = true;
      // this.$refs.address.focus();
    },
    selected(addressData, placeResultData) {
      this.addressData = addressData;
      placeResultData.reviews.forEach((review, index) => {
        review.id = index;
        review.review_response = null;
      });
      this.placeResultData = placeResultData;
      console.log(this.placeResultData);
      this.mapQuery = encodeURIComponent(placeResultData.name + ' ' + placeResultData.formatted_address);
    },
    updateAddress() {
      if (this.placeResultData) {
        this.$refs.address.update(this.placeResultData.name + ' ' + this.placeResultData.formatted_address);
      }
    },
    /**
     * Format result from Geo google APIs
     * @param place
     * @returns {{formatted output}}
     */
    formatResult(place) {
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        if (ADDRESS_COMPONENTS[addressType]) {
          let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }
      returnData['latitude'] = place.geometry.location.lat();
      returnData['longitude'] = place.geometry.location.lng();
      return returnData
    },
  },
}
</script>
<style lang="scss">
@import "../../src/assets/scss/review-form.scss";

.reply-row {
  margin: 25px 0 0 0;

  strong {
    margin-bottom: 5px;
    display: block
  }
}

.modal_content {

  .post_content {
    max-width: 28rem;
    margin-inline: auto;
    margin-bottom: 1rem;
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
      gap: .5rem;
    }

    .post_text_wrapper {
      padding: .5rem .8rem;

      p {
        margin: 0;
      }
    }

    .post_image_wrapper {
      border: 1px solid gray;

      .post_image {
        width: 100%;
      }
    }

    .edit_post_button {
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem 1rem;
      border-radius: 4px;
      cursor: pointer;

      @media (min-width: 768px) {
        position: absolute;
        left: 102%;
        top: 0
      }
    }
  }

  .post_image {
    height: 300px !important;
  }

  .regenrate_buttons_container {
    margin-bottom: .5rem;
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .regenerate_btn {
      margin-inline: .3rem;
      border: none;
      background: #06b050;
      color: #fff;
      padding: .3rem 1.5rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .social_media_buttons_container {
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .social_post_btn {
      margin-inline: .3rem;
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem .7rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .spin{
    width: 5rem;
    height: 5rem;
    margin-top: 30px;
  }
  .post{
    border: 1px solid black;
    height: 430px;
    width: 380px;
    text-align: center;
  }
}
</style>